<template>
<div>
  <div class="m-3">
    <b-button v-b-modal.modal-1>Event Settings</b-button>

    <b-modal id="modal-1" title="Event Settings" scrollable size="xl" centered>
      <event-settings/>
    </b-modal>
  </div>
</div>
</template>

<script>
import eventSettings from '@/components/eventSettings.vue';

export default {
  components: { 
    eventSettings 
  },

}
</script>

<style>

</style>